<template>
  <div class="w-1200">
    <div class="banner-info" style="background-color: #F1F1F1;">
      <div class="w-1200 d-f">
        <el-carousel class="index-swiper mt-1" height="500px">
          <el-carousel-item v-for="(item,index) in boostLaunchActiveDetail.img" :key="index">
            <img v-lazy="item.img" style="height:500px"/>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <el-button type="success" @click="helpKnife">帮忙助力</el-button>

    <el-button type="success" v-if="boostLaunchActiveDetail.noPeople<=0" @click="toCreateOrder">马上下单</el-button>


    <div>
      <div>助力信息</div>
      <div>{{boostLaunchActiveDetail.title}}</div>
      <div>
        <div v-if="boostLaunchActiveDetail.skuList.length>0">
          规格
          <text v-for="(skuLabel,index) in boostLaunchActiveDetail.skuList" :key="index">{{skuLabel}}</text>
        </div>
        <div v-else>单规格</div>
      </div>
      <div>
        <div>已助力金额{{boostLaunchActiveDetail.boostMoney}}</div>
        <div>已助力人数{{boostLaunchActiveDetail.doPeople}}</div>
        <div>还差助力{{boostLaunchActiveDetail.noPeople}}</div>
      </div>
    </div>
    <el-table :data="boostLaunchActiveDetail.peopleList" border stripe>
      <el-table-column type="index"/>
      <el-table-column label="头像" width="150" align="center">
        <template slot-scope="scope">
          <el-popover
            placement="right"
            trigger="hover"
          >
            <el-image
              slot="reference"
              style="width: 40px; height: 40px"
              :src="scope.row.userImage"
              fit="cover"
            />
            <el-image
              style="width: 220px; height: 220px"
              :src="scope.row.userImage"
              fit="cover"
            />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="昵称" prop="name"/>
      <el-table-column width="300" label="助力时间" prop="helpTime"/>
      <el-table-column width="350" label="助力金额" prop="helpAmount"/>

    </el-table>

    <div>
      <div>助力人信息</div>
      <div>昵称{{boostPersonInfo.name}}</div>
      <img style="width: 100px" :src="boostPersonInfo.userImage"/>

      小程序码：
      <img style="width: 100px" :src="boostPersonInfo.path"/>
    </div>
  </div>
</template>

<script>
import { boostLaunchActiveDetailUrl, boostShareDetailUrl, helpKnifeBoostUrl } from '@/api/index'

export default {
  name: 'BoostLaunchDetail',
  data () {
    return {
      boostLaunchActiveDetail: {
        img: [],
        skuList: [],
        peopleList: []
      },
      boostPersonInfo: {}
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.getBoostLaunchActiveDetail(this.$route.query.boostId)
      this.getBoostShareDetail(this.$route.query.boostId)
    },
    // 获取助力详情
    async getBoostLaunchActiveDetail (boostId) {
      const { data: res } = await this.$http.get(boostLaunchActiveDetailUrl + boostId, { params: { id: boostId } })
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.boostLaunchActiveDetail = res.data
      this.$set(this.boostLaunchActiveDetail, 'skuList', [])
      if (this.boostLaunchActiveDetail.skuName.length !== 0) {
        for (let i in this.boostLaunchActiveDetail.skuName) {
          this.boostLaunchActiveDetail.skuList.push(this.boostLaunchActiveDetail.skuName[i])
        }
      }
      console.log(boostShareDetailUrl)
      console.log(helpKnifeBoostUrl)
    },
    // 获取助力人详情
    async getBoostShareDetail (boostId) {
      const { data: res } = await this.$http.get(boostShareDetailUrl + boostId, { params: { id: boostId } })
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      let httpUrl = window.location.host
      this.boostPersonInfo = res.data
      this.boostPersonInfo.path = httpUrl + '/' + this.boostPersonInfo.path
    },
    // 帮忙助力
    async helpKnife () {
      const { data: res } = await this.$http.put(helpKnifeBoostUrl + this.$route.query.boostId, { id: this.$route.query.boostId })
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('助力成功，帮忙助力了' + res.data.money)
    },
    // 助力成功之后下单
    toCreateOrder () {
      this.$router.push({ path: '/editOrderInfo', query: { type: 'boost', boostId: this.$route.query.boostId } })
    },
  }
}
</script>

<style scoped>

</style>
